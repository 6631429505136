<template>
  <in-layout v-if="mtr !== null">
    <v-container class="pt-5" column slot="content" grid-list-lg>
      <v-form v-model="valid">
        <v-layout
          row
          align-center
          justify-space-between
        >
          <v-flex>
            <h1>Receber MTR {{mtr.number}}</h1>
          </v-flex>
        </v-layout>

        <v-divider class="mb-4"/>

        <v-layout>
          <v-flex md6 v-if="mtr.generator">
            <h2 class="title">Gerador</h2>

            <v-card
              class="pa-3 mb-3"
            >
              <v-card-text>
                <v-layout align-center>
                  <v-icon class="mr-4">mdi-cube</v-icon>
                  <v-layout class="text" column>
                    {{ mtr.generator.name}}
                    <span class="grey--text">
                      {{ formatString(mtr.generator.cpf_cnpj.length === 14 ?
                        pattern.cnpj : pattern.cpf, mtr.generator.cpf_cnpj )}}
                    </span>
                  </v-layout>
                </v-layout>
              </v-card-text>
            </v-card>

            <v-layout wrap>
              <v-flex xs8>
                <v-text-field
                  v-model="received_by"
                  label="Responsável pelo recebimento"
                  readonly
                />
              </v-flex>

              <v-flex xs3>
                <v-text-field
                  v-model="received_role"
                  label="Cargo"
                />
              </v-flex>

              <v-flex xs5>
                <v-menu
                  :close-on-content-click="false"
                  v-model="showDatepicker"
                  :nudge-right="40"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="received_at"
                    label="Data de recebimento"
                    persistent-hint
                    append-icon="mdi-calendar"
                    mask="##/##/####"
                    :rules="[validationRules.required]"
                    readonly
                    @focus="focusDatePicker"
                  />
                  <v-date-picker
                    v-model="date"
                    no-title
                    locale="pt-br"
                    @change="setDate"
                    :max="format(new Date(), 'YYYY-MM-DD')"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-spacer/>

          <v-flex md6 v-if="mtr.transporter">
            <h2 class="title">Transportador</h2>

            <v-card
              class="pa-3 mb-3"
            >
              <v-card-text>
                <v-layout align-center>
                  <v-icon class="mr-4">mdi-truck-delivery</v-icon>
                  <v-layout class="text" column>
                    {{ mtr.transporter.name }}
                    <span class="grey--text">
                      {{ formatString(mtr.transporter.cpf_cnpj.length === 14 ?
                        pattern.cnpj : pattern.cpf, mtr.transporter.cpf_cnpj)}}
                    </span>
                  </v-layout>
                </v-layout>
              </v-card-text>
            </v-card>

            <v-layout>
              <v-flex xs8>
                <v-text-field
                  v-model="transported_by"
                  label="Nome do motorista"
                  :rules="[validationRules.required]"
                />
              </v-flex>

              <v-flex xs3>
                <v-text-field
                  v-model="transport_license_plate"
                  label="Placa"
                  mask="AAA#N##"
                  :rules="[validationRules.required]"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>

      <v-layout column slot="list">
        <v-flex xs12 class="mb-3" v-for="(residue, index) in residues" :key="residue.id">
          <v-subheader class="pl-0">Resíduos</v-subheader>
          <v-divider class="mb-3"/>

          <v-card>
            <v-container style="height: 100%">
              <v-layout align-center>
                <v-avatar
                  class="mr-3"
                  size="36"
                  :color="residue.corrected ? 'red darken-3' : 'grey darken-1'"
                >
                  <strong class="white--text">{{ index + 1 }}</strong>
                </v-avatar>

                <v-flex xs8>
                  <v-layout column>
                    <span class="subheading">
                      {{ residue.residue.code }} - {{ residue.residue.name }}
                    </span>
                    <span class="grey--text">
                      Quantidade indicada:
                      <strong>{{ residue.quantity }} ({{ residue.measure_unit }})</strong>
                    </span>
                    <span class="grey--text">
                      Acondicionamento: <strong>{{ residue.packaging }}</strong>
                    </span>
                  </v-layout>
                </v-flex>

                <v-flex xs4>
                  <v-layout justify-end>
                    <mtr-correct-residue-modal
                      v-if="!residue.corrected && !residue.received"
                      :residue="residue"
                      :mtrId="mtr.id"
                      @correctedResidues="correctResidue"
                    />

                    <v-btn
                      v-if="!residue.received && !residue.corrected"
                      flat
                      color="green"
                      @click="receiveResidue(residue)"
                    >
                      Receber
                    </v-btn>

                    <v-chip
                      v-if="residue.received && !residue.corrected"
                      close
                      color="green"
                      text-color="white"
                      @input="cancelReceivement(residue)"
                    >
                      <strong>Recebido</strong>
                    </v-chip>

                    <v-chip
                      v-if="residue.corrected"
                      close
                      color="red darken-3"
                      text-color="white"
                      @input="cancelResidueCorrection(residue)"
                    >
                      <strong>Corrigido</strong>
                    </v-chip>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>

            <v-divider
              class="mb-3 mx-5"
              v-if="residue.corrected"
            />

            <v-layout class="px-5 pb-4" v-if="residue.corrected">
              <v-layout column>
                <span class="mb-2 grey--text text--darken-2 subheading font-weight-bold">
                  DADOS CORRIGIDOS
                </span>
                <span class="mb-2 subheading">
                  {{ correctedResidue(residue).residue.code }}
                  - {{ correctedResidue(residue).residue.name }}
                </span>

                <v-layout>
                  <v-flex xs4>
                    <v-layout column>
                      <span>
                        Quantidade recebida:
                        <strong>
                          {{ residue.quantity}}
                          ({{correctedResidue(residue).measure_unit}})
                        </strong>
                      </span>

                      <span>
                        Acondicionamento:
                        <strong>
                          {{ correctedResidue(residue).packaging }}
                        </strong>
                      </span>

                      <span>
                        Classe:
                        <strong>
                          {{ correctedResidue(residue).residue_class }}
                          </strong>
                      </span>
                    </v-layout>
                  </v-flex>
                  <v-flex xs4>
                    <v-layout column>
                      <span>
                        Estado Físico:
                        <strong>
                          {{ correctedResidue(residue).physical_state }}
                        </strong>
                      </span>

                      <span>
                        Tecnologia:
                        <strong>
                          {{ correctedResidue(residue).disposal_technology }}
                        </strong>
                      </span>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>

        <v-layout justify-center>
          <v-btn
            class="custom-button"
            color="grey"
            flat
            @click="$router.go(-1)"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="custom-button white--text"
            color="green"
            :disabled="!valid || !allResiduesReceived"
            @click="receiveMtr"
          >
            <v-icon left>mdi-download</v-icon>
            Receber
          </v-btn>
        </v-layout>
      </v-layout>
    </v-container>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import formatString from 'format-string-by-pattern';
import format from 'date-fns/format';
import { findIndex, isEmpty, pullAt, find } from 'lodash';
import validationRules from '@/mixins/validation-rules';
import MtrCorrectResidueModal from '@/components/mtr/MtrCorrectResidueModal.vue';
import mtrService from '@/services/mtr-service';

export default {
  name: 'ReceiveMtr',
  components: {
    InLayout,
    MtrCorrectResidueModal,
  },
  props: ['id'],
  data() {
    return {
      corrections: [],
      date: null,
      drivers: [],
      format,
      formatString,
      isEmpty,
      pattern: {
        cpf: 'XXX.XXX.XXX-XX',
        cnpj: 'XX.XXX.XXX/XXXX-XX',
      },
      received_at: null,
      residues: [],
      showDatepicker: false,
      received_by: '',
      received_role: '',
      transported_by: '',
      transport_license_plate: '',
      valid: false,
      validationRules,
    };
  },
  async mounted() {
    const response = await mtrService.show(this.id);
    this.$store.dispatch('mtr/setMtr', response.data);
    this.received_by = this.currentUser.user.name;
    this.setDate();
  },
  methods: {
    focusDatePicker() {
      setTimeout(() => {
        this.showDatepicker = true;
      }, 200);
    },
    setDate() {
      this.received_at = format((this.date || new Date()), 'DD/MM/YYYY');
      this.date = format((this.date || new Date()), 'YYYY-MM-DD');
      this.closeDatepicker();
    },
    async receiveResidue(residue) {
      const selectedResidueIndex = findIndex(
        this.residues,
        item => item.id === residue.id,
      );
      await mtrService.receive(this.mtr.id, this.residues[selectedResidueIndex].id);
      this.residues[selectedResidueIndex].received = true;
    },
    async cancelReceivement(residue) {
      const selectedResidueIndex = findIndex(
        this.residues,
        item => item.residue.id === residue.residue.id,
      );
      await mtrService
        .cancelReceivement(this.mtr.id, this.residues[selectedResidueIndex].id);
      this.residues[selectedResidueIndex].received = false;
    },
    correctResidue(residue) {
      const selectedResidueIndex = findIndex(this.residues, ['id', residue.previousId]);
      this.residues[selectedResidueIndex].residueAdjustedId = residue.residueAdjustedId;
      this.residues[selectedResidueIndex].corrected = true;
      this.residues[selectedResidueIndex].received = true;
      this.corrections.push({ ...residue, id: residue.previousId });
    },
    async cancelResidueCorrection(residue) {
      const selectedResidueIndex = findIndex(this.residues, ['id', residue.id]);
      const correctionIndex = findIndex(this.corrections, ['previousId', residue.id]);
      await mtrService.cancelAdjustedMtrResidueItem(
        this.mtr.id,
        (residue.residueAdjustedId || residue.residue_adjusted.id),
      );
      this.residues[selectedResidueIndex].corrected = false;
      this.residues[selectedResidueIndex].received = false;
      pullAt(this.corrections, [correctionIndex]);
    },
    correctedResidue(residue) {
      return find(this.corrections, ['previousId', residue.id]);
    },
    receiveMtr() {
      const { status } = this.$route.params;

      this.$store.dispatch('mtr/receiveMtr', {
        id: this.id,
        received_at: this.date,
        received_by: this.received_by,
        received_role: this.received_role,
        transport_license_plate: this.transport_license_plate,
        transported_by: this.transported_by,
        status,
      })
        .then(() => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'MTR recebido com sucesso!',
            messageIcon: 'mdi-check',
          });
          this.$router.push('/destinador');
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
    closeDatepicker() {
      setTimeout(() => {
        this.showDatepicker = false;
      }, 200);
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters['auth/currentUser'];
    },
    mtr() {
      return this.$store.getters['mtr/selectedMtr'];
    },
    allResiduesReceived() {
      return true;
    },
  },
  watch: {
    mtr(val) {
      this.receivedAt = val.receivedAt !== null ? format(parseInt(val.receivedAt, 10), 'DD/MM/YYYY') : format(new Date(), 'DD/MM/YYYY');
      this.received_role = val.received_role;
      this.transported_by = val.transported_by;
      this.transport_license_plate = val.transport_license_plate;
      this.residues = val.residues.map((item) => {
        if (!isEmpty(item.residue_adjusted)) {
          this.corrections.push({
            ...item.residue_adjusted,
            residue: item.residue,
            previousId: item.id,
          });
          return {
            ...item,
            received: true,
            corrected: true,
          };
        }
        if (this.mtr.status === 'received' || !isEmpty(item.received_at)) {
          return {
            ...item,
            received: true,
            corrected: false,
          };
        }
        return {
          ...item,
          received: false,
          corrected: false,
        };
      });
    },
  },
};
</script>

<style scoped>
.generator {
  margin: 0.5rem 0 1rem 47px;
}
.custom-button {
  width: 160px;
}
</style>
