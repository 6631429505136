<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-btn color="red" slot="activator" flat>
      Corrigir
    </v-btn>
    <v-form ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="success" dark flat>
          <span class="headline">Corrigir Resíduo</span>
          <v-spacer></v-spacer>
          <v-btn flat icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-autocomplete
                  v-model="selectedResidue"
                  :items="residueOptions"
                  return-object
                  :loading="isFetchingResidues"
                  no-data-text="Nenhum resíduo encontrado"
                  :hide-no-data="!residuesSearch || isUserTyping"
                  no-filter
                  :search-input.sync="residuesSearch"
                  label="Buscar resíduo por: Código/Palavra"
                  :rules="[rules.required]"
                  prepend-icon="mdi-feature-search-outline"
                  :menu-props="{maxWidth:'600'}"
                  @input="changeSelectedResidue"
                >
                  <template
                    slot="item"
                    slot-scope="data">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{data.item}}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </template>
                </v-autocomplete>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  v-model="editedItem.quantity"
                  v-money="money"
                  label="Quantidade"
                  :rules="[rules.required, rules.number]"
                  min="0"
                />
              </v-flex>

              <v-flex xs12 md4>
                <v-select
                  :items="measureUnityNames"
                  v-model="editedItem.measure_unit"
                  label="Unidade"
                  :rules="[rules.required]"
                />
              </v-flex>

              <v-flex xs12 md4 v-if="hasDensity">
                <v-text-field type="number"
                  v-model.number="editedItem.density"
                  label="Densidade"
                  :rules="[rules.required, rules.number]"
                  :suffix="densitySufix(editedItem.measure_unit)"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-select
                  :items="classes"
                  v-model="editedItem.residue_class"
                  label="Classes"
                  :rules="[rules.required]"
                  :placeholder="classFieldPlaceholder"
                  :disabled="!editedItem.residue"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-select
                  :items="physicalStatesNames"
                  v-model="editedItem.physical_state"
                  label="Estado físico"
                  :rules="[rules.required]"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-select
                  :items="packagings"
                  v-model="editedItem.packaging"
                  label="Acondicionamento"
                  :disabled="!editedItem.physical_state"
                  :placeholder="hasPhysicalStatePlaceHolder"
                  :rules="[rules.required]"
                />
              </v-flex>

              <v-flex xs12 md6>
                <v-select
                  :items="technologiesNames"
                  v-model="editedItem.disposal_technology"
                  label="Tecnologia"
                  :rules="[rules.required]"
                />
              </v-flex>

              <v-flex xs12>
                <v-textarea
                  label="Observação"
                  v-model="editedItem.observation"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            @click.native="close"
            flat
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            @click.native="save"
            :disabled="!valid"
            flat
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import validationRules from '@/mixins/validation-rules';
import { VMoney } from 'v-money';
import { forEach, omit } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import mtrService from '@/services/mtr-service';

export default {
  name: 'mtr-correct-residue-modal',
  directives: { money: VMoney },
  data() {
    return {
      valid: false,
      rules: validationRules,
      isFetchingResidues: false,
      residuesSearch: null,
      formData: {},
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false,
      },
      headers: [
        { text: 'Cód', value: 'cod' },
        { text: 'Resíduo', value: 'residue' },
        { text: 'Estado físico', value: 'physical_state' },
        { text: 'Classe', value: 'class' },
        { text: 'Acondicionamento', value: 'packaging' },
        { text: 'Quantidade', value: 'quantity', sortable: false },
        { text: 'Unidade', value: 'unit', sortable: false },
        { text: 'Tecnologia', value: 'technology', sortable: false },
        { text: 'Ações', value: '', sortable: false },
      ],
      dialog: false,
      residues: [],
      fetchedResidues: [],
      residueOptions: [],
      editedItem: {},
      isUserTyping: false,
      classes: [],
      selectedResidue: '',
    };
  },
  props: ['residue', 'mtrId'],
  beforeMount() {
    this.fetchMeasureUnities();
    this.fetchPhysicalStates();
    this.fetchPackagings();
    this.fetchTechnologies();
  },
  mounted() {
    this.setInitialEditedItem();
  },
  methods: {
    ...mapActions({
      fetchMeasureUnities: 'mtr/fetchMeasureUnities',
      fetchPhysicalStates: 'mtr/fetchPhysicalStates',
      fetchPackagings: 'mtr/fetchPackagings',
      fetchTechnologies: 'mtr/fetchTechnologies',
    }),

    setInitialEditedItem() {
      this.editedItem = {
        residue: omit(this.residue.residue, 'subcategory'),
        density: this.residue.density,
        disposal_technology: this.residue.disposal_technology,
        mtr_residue_adjusted_id: this.residue.id,
        measure_unit: this.residue.measure_unit,
        observation: '',
        physical_state: this.residue.physical_state,
        packaging: this.residue.packaging,
        quantity: this.residue.quantity,
        residue_class: this.residue.residue_class,
        received_at: new Date(),
      };
      this.fetchResidues(this.residue.residue.code);
      this.selectedResidue = `${this.residue.residue.code} - ${this.residue.residue.name}`;
    },

    changeSelectedResidue(value) {
      forEach(this.fetchedResidues, (item) => {
        const codAndDescriptionString = `${item.code} - ${item.name}`;
        if (codAndDescriptionString === value) {
          this.editedItem.residue = item;
          this.setResidueClasses(item);
        }
      });
    },

    close() {
      this.dialog = false;
      this.isFetchingResidues = false;
    },

    async save() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.editedItem.residue_data = omit(this.editedItem.residue, ['created_at', 'updated_at', 'residue_sub_category_id']);
      const response = await mtrService
        .adjustMtrResidue(
          this.mtrId,
          this.residue.id,
          this.editedItem,
        );
      this.$emit('correctedResidues', {
        ...this.editedItem,
        previousId: this.previousId,
        corrected: true,
        residueAdjustedId: response.residue_adjusted_id,
      });

      this.close();
      return true;
    },

    densitySufix(unit) {
      const densitySufixPerUnit = {
        'Metro Cúbico': 't/m³',
        Litro: 'g/cm³',
      };
      return densitySufixPerUnit[unit] || '';
    },

    fetchResidues(val) {
      const queryString = val.length >= 8 ? val.slice(0, 6) : val;

      mtrService.residuesList(queryString)
        .then(({ data }) => {
          this.fetchedResidues = data;
          this.residueOptions = data.map(residue => `${residue.code} - ${residue.name}`);
          forEach(this.fetchedResidues, (item) => {
            if (item.code === this.residue.residue.code) {
              this.setResidueClasses(item);
            }
          });
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isFetchingResidues = false;
        });
    },

    setResidueClasses(residue) {
      this.classes = residue.residue_classes.map(cl => cl.name);
    },

  },
  computed: {
    ...mapGetters({
      measureUnities: 'mtr/measureUnities',
      physicalStates: 'mtr/physicalStates',
      technologies: 'mtr/technologies',
    }),

    measureUnityNames() {
      return this.measureUnities.map(unity => unity.name);
    },

    physicalStatesNames() {
      return this.physicalStates.map(state => state.name);
    },

    technologiesNames() {
      return this.technologies.map(state => state.name);
    },

    hasPhysicalStatePlaceHolder() {
      return !this.editedItem.physicalState ? 'Selecione um estado físico' : '';
    },

    classFieldPlaceholder() {
      return !this.editedItem.residue ? 'Selecione um resíduo' : '';
    },

    packagings() {
      const physicalState = this.editedItem.physical_state;
      if (!physicalState) {
        return [];
      }
      const physicalStateSelected = this.physicalStates
        .find(state => state.name === physicalState);

      if (!physicalStateSelected) {
        return [];
      }

      return physicalStateSelected.packagings.map(p => p.name);
    },

    hasDensity() {
      const measureUnitiesWithDensity = ['Metro Cúbico', 'Litro'];
      const { measureUnity } = this.editedItem;
      return measureUnity && measureUnitiesWithDensity.includes(measureUnity);
    },

    previousId() {
      return this.$props.residue.id;
    },
  },
  watch: {
    dialog(val) {
      if (!val) this.close();
    },
    residuesSearch(val) {
      if (!val) return;
      clearTimeout(this.timeout);
      this.isUserTyping = true;
      this.timeout = setTimeout(() => {
        this.isUserTyping = false;
        this.fetchResidues(val);
      }, 500);
    },
  },
};
</script>

<style lang="scss">
</style>
