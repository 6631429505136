var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mtr !== null
    ? _c(
        "in-layout",
        [
          _c(
            "v-container",
            {
              staticClass: "pt-5",
              attrs: { slot: "content", column: "", "grid-list-lg": "" },
              slot: "content",
            },
            [
              _c(
                "v-form",
                {
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        "align-center": "",
                        "justify-space-between": "",
                      },
                    },
                    [
                      _c("v-flex", [
                        _c("h1", [
                          _vm._v("Receber MTR " + _vm._s(_vm.mtr.number)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "mb-4" }),
                  _c(
                    "v-layout",
                    [
                      _vm.mtr.generator
                        ? _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("h2", { staticClass: "title" }, [
                                _vm._v("Gerador"),
                              ]),
                              _c(
                                "v-card",
                                { staticClass: "pa-3 mb-3" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { "align-center": "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-4" },
                                            [_vm._v("mdi-cube")]
                                          ),
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "text",
                                              attrs: { column: "" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.mtr.generator.name
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "grey--text" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.formatString(
                                                          _vm.mtr.generator
                                                            .cpf_cnpj.length ===
                                                            14
                                                            ? _vm.pattern.cnpj
                                                            : _vm.pattern.cpf,
                                                          _vm.mtr.generator
                                                            .cpf_cnpj
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs8: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Responsável pelo recebimento",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.received_by,
                                          callback: function ($$v) {
                                            _vm.received_by = $$v
                                          },
                                          expression: "received_by",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs3: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Cargo" },
                                        model: {
                                          value: _vm.received_role,
                                          callback: function ($$v) {
                                            _vm.received_role = $$v
                                          },
                                          expression: "received_role",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs5: "" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "close-on-content-click": false,
                                            "nudge-right": 40,
                                            "offset-y": "",
                                            "max-width": "290px",
                                            "min-width": "290px",
                                          },
                                          model: {
                                            value: _vm.showDatepicker,
                                            callback: function ($$v) {
                                              _vm.showDatepicker = $$v
                                            },
                                            expression: "showDatepicker",
                                          },
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              slot: "activator",
                                              label: "Data de recebimento",
                                              "persistent-hint": "",
                                              "append-icon": "mdi-calendar",
                                              mask: "##/##/####",
                                              rules: [
                                                _vm.validationRules.required,
                                              ],
                                              readonly: "",
                                            },
                                            on: { focus: _vm.focusDatePicker },
                                            slot: "activator",
                                            model: {
                                              value: _vm.received_at,
                                              callback: function ($$v) {
                                                _vm.received_at = $$v
                                              },
                                              expression: "received_at",
                                            },
                                          }),
                                          _c("v-date-picker", {
                                            attrs: {
                                              "no-title": "",
                                              locale: "pt-br",
                                              max: _vm.format(
                                                new Date(),
                                                "YYYY-MM-DD"
                                              ),
                                            },
                                            on: { change: _vm.setDate },
                                            model: {
                                              value: _vm.date,
                                              callback: function ($$v) {
                                                _vm.date = $$v
                                              },
                                              expression: "date",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _vm.mtr.transporter
                        ? _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("h2", { staticClass: "title" }, [
                                _vm._v("Transportador"),
                              ]),
                              _c(
                                "v-card",
                                { staticClass: "pa-3 mb-3" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { "align-center": "" } },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-4" },
                                            [_vm._v("mdi-truck-delivery")]
                                          ),
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "text",
                                              attrs: { column: "" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.mtr.transporter.name
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "grey--text" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.formatString(
                                                          _vm.mtr.transporter
                                                            .cpf_cnpj.length ===
                                                            14
                                                            ? _vm.pattern.cnpj
                                                            : _vm.pattern.cpf,
                                                          _vm.mtr.transporter
                                                            .cpf_cnpj
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs8: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Nome do motorista",
                                          rules: [_vm.validationRules.required],
                                        },
                                        model: {
                                          value: _vm.transported_by,
                                          callback: function ($$v) {
                                            _vm.transported_by = $$v
                                          },
                                          expression: "transported_by",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs3: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Placa",
                                          mask: "AAA#N##",
                                          rules: [_vm.validationRules.required],
                                        },
                                        model: {
                                          value: _vm.transport_license_plate,
                                          callback: function ($$v) {
                                            _vm.transport_license_plate = $$v
                                          },
                                          expression: "transport_license_plate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { slot: "list", column: "" }, slot: "list" },
                [
                  _vm._l(_vm.residues, function (residue, index) {
                    return _c(
                      "v-flex",
                      {
                        key: residue.id,
                        staticClass: "mb-3",
                        attrs: { xs12: "" },
                      },
                      [
                        _c("v-subheader", { staticClass: "pl-0" }, [
                          _vm._v("Resíduos"),
                        ]),
                        _c("v-divider", { staticClass: "mb-3" }),
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-container",
                              { staticStyle: { height: "100%" } },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { "align-center": "" } },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        staticClass: "mr-3",
                                        attrs: {
                                          size: "36",
                                          color: residue.corrected
                                            ? "red darken-3"
                                            : "grey darken-1",
                                        },
                                      },
                                      [
                                        _c(
                                          "strong",
                                          { staticClass: "white--text" },
                                          [_vm._v(_vm._s(index + 1))]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { column: "" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "subheading" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      residue.residue.code
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      residue.residue.name
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "grey--text" },
                                              [
                                                _vm._v(
                                                  "\n                    Quantidade indicada:\n                    "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(residue.quantity) +
                                                      " (" +
                                                      _vm._s(
                                                        residue.measure_unit
                                                      ) +
                                                      ")"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "grey--text" },
                                              [
                                                _vm._v(
                                                  "\n                    Acondicionamento: "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(residue.packaging)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "" } },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { "justify-end": "" } },
                                          [
                                            !residue.corrected &&
                                            !residue.received
                                              ? _c(
                                                  "mtr-correct-residue-modal",
                                                  {
                                                    attrs: {
                                                      residue: residue,
                                                      mtrId: _vm.mtr.id,
                                                    },
                                                    on: {
                                                      correctedResidues:
                                                        _vm.correctResidue,
                                                    },
                                                  }
                                                )
                                              : _vm._e(),
                                            !residue.received &&
                                            !residue.corrected
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      color: "green",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.receiveResidue(
                                                          residue
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Receber\n                  "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            residue.received &&
                                            !residue.corrected
                                              ? _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      close: "",
                                                      color: "green",
                                                      "text-color": "white",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.cancelReceivement(
                                                          residue
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("Recebido"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                            residue.corrected
                                              ? _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      close: "",
                                                      color: "red darken-3",
                                                      "text-color": "white",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.cancelResidueCorrection(
                                                          residue
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("strong", [
                                                      _vm._v("Corrigido"),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            residue.corrected
                              ? _c("v-divider", { staticClass: "mb-3 mx-5" })
                              : _vm._e(),
                            residue.corrected
                              ? _c(
                                  "v-layout",
                                  { staticClass: "px-5 pb-4" },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { column: "" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mb-2 grey--text text--darken-2 subheading font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              "\n                DADOS CORRIGIDOS\n              "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "mb-2 subheading" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.correctedResidue(residue)
                                                    .residue.code
                                                ) +
                                                "\n                - " +
                                                _vm._s(
                                                  _vm.correctedResidue(residue)
                                                    .residue.name
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-layout",
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs4: "" } },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { column: "" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      Quantidade recebida:\n                      "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              residue.quantity
                                                            ) +
                                                            "\n                        (" +
                                                            _vm._s(
                                                              _vm.correctedResidue(
                                                                residue
                                                              ).measure_unit
                                                            ) +
                                                            ")\n                      "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      Acondicionamento:\n                      "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.correctedResidue(
                                                                residue
                                                              ).packaging
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      Classe:\n                      "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.correctedResidue(
                                                                residue
                                                              ).residue_class
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs4: "" } },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { column: "" } },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      Estado Físico:\n                      "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.correctedResidue(
                                                                residue
                                                              ).physical_state
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("span", [
                                                      _vm._v(
                                                        "\n                      Tecnologia:\n                      "
                                                      ),
                                                      _c("strong", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.correctedResidue(
                                                                residue
                                                              )
                                                                .disposal_technology
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "custom-button",
                          attrs: { color: "grey", flat: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("\n          Cancelar\n        ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "custom-button white--text",
                          attrs: {
                            color: "green",
                            disabled: !_vm.valid || !_vm.allResiduesReceived,
                          },
                          on: { click: _vm.receiveMtr },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-download"),
                          ]),
                          _vm._v("\n          Receber\n        "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }